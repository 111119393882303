import { default as history_45entries2PLE9inazHMeta } from "/opt/render/project/src/pages/acp/history-entries.vue?macro=true";
import { default as indexWk9MU9LfSCMeta } from "/opt/render/project/src/pages/acp/index.vue?macro=true";
import { default as lawyer_45dashboardYDzzbGuXr2Meta } from "/opt/render/project/src/pages/acp/lawyer-dashboard.vue?macro=true";
import { default as reset_45usersSmZc4PPfZAMeta } from "/opt/render/project/src/pages/acp/reset-users.vue?macro=true";
import { default as user_45statusqex720WUJgMeta } from "/opt/render/project/src/pages/acp/user-status.vue?macro=true";
import { default as financial_45viewsZaYY5ttv4xMeta } from "/opt/render/project/src/pages/alignment/financial-views.vue?macro=true";
import { default as indexWlMuvSmmZPMeta } from "/opt/render/project/src/pages/alignment/index.vue?macro=true";
import { default as partners_45postmarital_45propertymq7pezgB4yMeta } from "/opt/render/project/src/pages/alignment/partners-postmarital-property.vue?macro=true";
import { default as partners_45premarital_45propertyXe2e5NEWdqMeta } from "/opt/render/project/src/pages/alignment/partners-premarital-property.vue?macro=true";
import { default as postmarital_45propertyQJPltT7d2mMeta } from "/opt/render/project/src/pages/alignment/postmarital-property.vue?macro=true";
import { default as premarital_45propertyz1X4vGlziFMeta } from "/opt/render/project/src/pages/alignment/premarital-property.vue?macro=true";
import { default as authV198ReNtLLMeta } from "/opt/render/project/src/pages/auth.vue?macro=true";
import { default as _91id_93Lq54qzWGUiMeta } from "/opt/render/project/src/pages/basic-information/[id].vue?macro=true";
import { default as basic_45informationn8q3WCHe7iMeta } from "/opt/render/project/src/pages/basic-information.vue?macro=true";
import { default as advisory_45letterJx9bvopQcwMeta } from "/opt/render/project/src/pages/clients/[id]/advisory-letter.vue?macro=true";
import { default as advisory_45letternkcBgRuD8RMeta } from "/opt/render/project/src/pages/clients/[id]/docs/advisory-letter.vue?macro=true";
import { default as assets_45partnerIcvW3nEFfuMeta } from "/opt/render/project/src/pages/clients/[id]/docs/assets-partner.vue?macro=true";
import { default as assetsRrCGqkVIMaMeta } from "/opt/render/project/src/pages/clients/[id]/docs/assets.vue?macro=true";
import { default as commentst66sztRquQMeta } from "/opt/render/project/src/pages/clients/[id]/docs/comments.vue?macro=true";
import { default as engagment_45letterd6S9lcRBzFMeta } from "/opt/render/project/src/pages/clients/[id]/docs/engagment-letter.vue?macro=true";
import { default as familyWo331rTO7eMeta } from "/opt/render/project/src/pages/clients/[id]/docs/family.vue?macro=true";
import { default as financial_45viewsPX9tcbNgM3Meta } from "/opt/render/project/src/pages/clients/[id]/docs/financial-views.vue?macro=true";
import { default as indextn0i7wpAyrMeta } from "/opt/render/project/src/pages/clients/[id]/docs/index.vue?macro=true";
import { default as marital_45property_45partnerf6KQMn5u9hMeta } from "/opt/render/project/src/pages/clients/[id]/docs/marital-property-partner.vue?macro=true";
import { default as marital_45propertyz3MWFXcF22Meta } from "/opt/render/project/src/pages/clients/[id]/docs/marital-property.vue?macro=true";
import { default as questionsKgQMvIhqmKMeta } from "/opt/render/project/src/pages/clients/[id]/docs/questions.vue?macro=true";
import { default as indexukfgsWVD5UMeta } from "/opt/render/project/src/pages/clients/[id]/index.vue?macro=true";
import { default as original_45draftr711Nc86o9Meta } from "/opt/render/project/src/pages/clients/[id]/original-draft.vue?macro=true";
import { default as _91id_937gkwADbSVOMeta } from "/opt/render/project/src/pages/clients/[id].vue?macro=true";
import { default as create_45profilessD9iru46sMeta } from "/opt/render/project/src/pages/create-profile.vue?macro=true";
import { default as editor_45testpZZNUeGRODMeta } from "/opt/render/project/src/pages/editor-test.vue?macro=true";
import { default as _91id_930VjZB0uhn4Meta } from "/opt/render/project/src/pages/eligibility/[id].vue?macro=true";
import { default as eligibilityf1ZrsDCNcCMeta } from "/opt/render/project/src/pages/eligibility.vue?macro=true";
import { default as _91id_93nW51M3hbq2Meta } from "/opt/render/project/src/pages/financial-philosophy/[id].vue?macro=true";
import { default as financial_45philosophyvteLhKXBXxMeta } from "/opt/render/project/src/pages/financial-philosophy.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as date19f3dQ6m8rMeta } from "/opt/render/project/src/pages/ineligible/date.vue?macro=true";
import { default as stateVndyr0vPkdMeta } from "/opt/render/project/src/pages/ineligible/state.vue?macro=true";
import { default as ineligibleWUA0xgMT9gMeta } from "/opt/render/project/src/pages/ineligible.vue?macro=true";
import { default as verifyETLyMudwYuMeta } from "/opt/render/project/src/pages/invite/verify.vue?macro=true";
import { default as lawyer_45authfYOiA6414sMeta } from "/opt/render/project/src/pages/lawyer-auth.vue?macro=true";
import { default as lawyer_45dashboardrDzTVqukOyMeta } from "/opt/render/project/src/pages/lawyer-dashboard.vue?macro=true";
import { default as lawyer_45login5EhNeRgHNrMeta } from "/opt/render/project/src/pages/lawyer-login.vue?macro=true";
import { default as lawyer_45logoutc7J2120KF1Meta } from "/opt/render/project/src/pages/lawyer-logout.vue?macro=true";
import { default as advisory_45letter_45reviewwg31btdtMEMeta } from "/opt/render/project/src/pages/lawyer-review/advisory-letter-review.vue?macro=true";
import { default as advisory_45letterMlzpAWz50iMeta } from "/opt/render/project/src/pages/lawyer-review/advisory-letter.vue?macro=true";
import { default as choose_45lawyerXJsIHwly3RMeta } from "/opt/render/project/src/pages/lawyer-review/choose-lawyer.vue?macro=true";
import { default as draftEZqsotbNjAMeta } from "/opt/render/project/src/pages/lawyer-review/draft.vue?macro=true";
import { default as engagement_45letter_45reviewZlX9oSw3hRMeta } from "/opt/render/project/src/pages/lawyer-review/engagement-letter-review.vue?macro=true";
import { default as engagement_45letter3Ads5iK1ygMeta } from "/opt/render/project/src/pages/lawyer-review/engagement-letter.vue?macro=true";
import { default as finalizedVF0ipL2Zn4Meta } from "/opt/render/project/src/pages/lawyer-review/finalized.vue?macro=true";
import { default as indexXCMD03AveLMeta } from "/opt/render/project/src/pages/lawyer-review/index.vue?macro=true";
import { default as own_45lawyer1qOB9en47HMeta } from "/opt/render/project/src/pages/lawyer-review/own-lawyer.vue?macro=true";
import { default as previewIf3GBcsZhBMeta } from "/opt/render/project/src/pages/lawyer-review/preview.vue?macro=true";
import { default as questionsTr0RikSLLqMeta } from "/opt/render/project/src/pages/lawyer-review/questions.vue?macro=true";
import { default as your_45reviewTvaPHH6FcAMeta } from "/opt/render/project/src/pages/lawyer-review/your-review.vue?macro=true";
import { default as lawyer_45reviewVhPEL9dp08Meta } from "/opt/render/project/src/pages/lawyer-review.vue?macro=true";
import { default as verifylEA18QJ7UUMeta } from "/opt/render/project/src/pages/lawyer/invite/verify.vue?macro=true";
import { default as loginjhbtmcfyq0Meta } from "/opt/render/project/src/pages/login.vue?macro=true";
import { default as logoutRNSjl0CyuuMeta } from "/opt/render/project/src/pages/logout.vue?macro=true";
import { default as my_45assetshFoyAmV5AbMeta } from "/opt/render/project/src/pages/my-assets.vue?macro=true";
import { default as my_45family98tpEpqnc1Meta } from "/opt/render/project/src/pages/my-family.vue?macro=true";
import { default as advisory_45letterHiDAL41OYJMeta } from "/opt/render/project/src/pages/our-prenup/advisory-letter.vue?macro=true";
import { default as engagement_45letter8ye2pxNDhqMeta } from "/opt/render/project/src/pages/our-prenup/engagement-letter.vue?macro=true";
import { default as indexm1Tx3YQQMAMeta } from "/opt/render/project/src/pages/our-prenup/index.vue?macro=true";
import { default as lawyer_45commentsWsoXOKfqTWMeta } from "/opt/render/project/src/pages/our-prenup/lawyer-comments.vue?macro=true";
import { default as my_45assetsn33QBZhoQUMeta } from "/opt/render/project/src/pages/our-prenup/my-assets.vue?macro=true";
import { default as my_45familyvvcJoHquBsMeta } from "/opt/render/project/src/pages/our-prenup/my-family.vue?macro=true";
import { default as my_45marital_45propertyLgdgjthoeiMeta } from "/opt/render/project/src/pages/our-prenup/my-marital-property.vue?macro=true";
import { default as our_45financial_45viewscd86cpruCfMeta } from "/opt/render/project/src/pages/our-prenup/our-financial-views.vue?macro=true";
import { default as partners_45assetsGGAFFASmedMeta } from "/opt/render/project/src/pages/our-prenup/partners-assets.vue?macro=true";
import { default as partners_45marital_45propertynt5T9VPPV6Meta } from "/opt/render/project/src/pages/our-prenup/partners-marital-property.vue?macro=true";
import { default as questionsRi5qVFed8jMeta } from "/opt/render/project/src/pages/our-prenup/questions.vue?macro=true";
import { default as sign_45prenupNGrr9J7xLeMeta } from "/opt/render/project/src/pages/our-prenup/sign-prenup.vue?macro=true";
import { default as waiver_45of_45attorneyOyw9Qu27fvMeta } from "/opt/render/project/src/pages/our-prenup/waiver-of-attorney.vue?macro=true";
import { default as packageQyReJjidrGMeta } from "/opt/render/project/src/pages/package.vue?macro=true";
import { default as postnup_45waitlistnfTsC5TTfOMeta } from "/opt/render/project/src/pages/postnup-waitlist.vue?macro=true";
import { default as scroll_45progress_45demoOcuunN6MnxMeta } from "/opt/render/project/src/pages/scroll-progress-demo.vue?macro=true";
import { default as signupqU7ffU0d9PMeta } from "/opt/render/project/src/pages/signup.vue?macro=true";
import { default as user_45advisement_45letterjHiCdasUsPMeta } from "/opt/render/project/src/pages/user-advisement-letter.vue?macro=true";
import { default as waiver_45of_45attorneyt7734rqmc3Meta } from "/opt/render/project/src/pages/waiver-of-attorney.vue?macro=true";
export default [
  {
    name: history_45entries2PLE9inazHMeta?.name ?? "acp-history-entries",
    path: history_45entries2PLE9inazHMeta?.path ?? "/acp/history-entries",
    meta: history_45entries2PLE9inazHMeta || {},
    alias: history_45entries2PLE9inazHMeta?.alias || [],
    redirect: history_45entries2PLE9inazHMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/acp/history-entries.vue").then(m => m.default || m)
  },
  {
    name: indexWk9MU9LfSCMeta?.name ?? "acp",
    path: indexWk9MU9LfSCMeta?.path ?? "/acp",
    meta: indexWk9MU9LfSCMeta || {},
    alias: indexWk9MU9LfSCMeta?.alias || [],
    redirect: indexWk9MU9LfSCMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/acp/index.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45dashboardYDzzbGuXr2Meta?.name ?? "acp-lawyer-dashboard",
    path: lawyer_45dashboardYDzzbGuXr2Meta?.path ?? "/acp/lawyer-dashboard",
    meta: lawyer_45dashboardYDzzbGuXr2Meta || {},
    alias: lawyer_45dashboardYDzzbGuXr2Meta?.alias || [],
    redirect: lawyer_45dashboardYDzzbGuXr2Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/acp/lawyer-dashboard.vue").then(m => m.default || m)
  },
  {
    name: reset_45usersSmZc4PPfZAMeta?.name ?? "acp-reset-users",
    path: reset_45usersSmZc4PPfZAMeta?.path ?? "/acp/reset-users",
    meta: reset_45usersSmZc4PPfZAMeta || {},
    alias: reset_45usersSmZc4PPfZAMeta?.alias || [],
    redirect: reset_45usersSmZc4PPfZAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/acp/reset-users.vue").then(m => m.default || m)
  },
  {
    name: user_45statusqex720WUJgMeta?.name ?? "acp-user-status",
    path: user_45statusqex720WUJgMeta?.path ?? "/acp/user-status",
    meta: user_45statusqex720WUJgMeta || {},
    alias: user_45statusqex720WUJgMeta?.alias || [],
    redirect: user_45statusqex720WUJgMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/acp/user-status.vue").then(m => m.default || m)
  },
  {
    name: financial_45viewsZaYY5ttv4xMeta?.name ?? "alignment-financial-views",
    path: financial_45viewsZaYY5ttv4xMeta?.path ?? "/alignment/financial-views",
    meta: financial_45viewsZaYY5ttv4xMeta || {},
    alias: financial_45viewsZaYY5ttv4xMeta?.alias || [],
    redirect: financial_45viewsZaYY5ttv4xMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/alignment/financial-views.vue").then(m => m.default || m)
  },
  {
    name: indexWlMuvSmmZPMeta?.name ?? "alignment",
    path: indexWlMuvSmmZPMeta?.path ?? "/alignment",
    meta: indexWlMuvSmmZPMeta || {},
    alias: indexWlMuvSmmZPMeta?.alias || [],
    redirect: indexWlMuvSmmZPMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/alignment/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45postmarital_45propertymq7pezgB4yMeta?.name ?? "alignment-partners-postmarital-property",
    path: partners_45postmarital_45propertymq7pezgB4yMeta?.path ?? "/alignment/partners-postmarital-property",
    meta: partners_45postmarital_45propertymq7pezgB4yMeta || {},
    alias: partners_45postmarital_45propertymq7pezgB4yMeta?.alias || [],
    redirect: partners_45postmarital_45propertymq7pezgB4yMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/alignment/partners-postmarital-property.vue").then(m => m.default || m)
  },
  {
    name: partners_45premarital_45propertyXe2e5NEWdqMeta?.name ?? "alignment-partners-premarital-property",
    path: partners_45premarital_45propertyXe2e5NEWdqMeta?.path ?? "/alignment/partners-premarital-property",
    meta: partners_45premarital_45propertyXe2e5NEWdqMeta || {},
    alias: partners_45premarital_45propertyXe2e5NEWdqMeta?.alias || [],
    redirect: partners_45premarital_45propertyXe2e5NEWdqMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/alignment/partners-premarital-property.vue").then(m => m.default || m)
  },
  {
    name: postmarital_45propertyQJPltT7d2mMeta?.name ?? "alignment-postmarital-property",
    path: postmarital_45propertyQJPltT7d2mMeta?.path ?? "/alignment/postmarital-property",
    meta: postmarital_45propertyQJPltT7d2mMeta || {},
    alias: postmarital_45propertyQJPltT7d2mMeta?.alias || [],
    redirect: postmarital_45propertyQJPltT7d2mMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/alignment/postmarital-property.vue").then(m => m.default || m)
  },
  {
    name: premarital_45propertyz1X4vGlziFMeta?.name ?? "alignment-premarital-property",
    path: premarital_45propertyz1X4vGlziFMeta?.path ?? "/alignment/premarital-property",
    meta: premarital_45propertyz1X4vGlziFMeta || {},
    alias: premarital_45propertyz1X4vGlziFMeta?.alias || [],
    redirect: premarital_45propertyz1X4vGlziFMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/alignment/premarital-property.vue").then(m => m.default || m)
  },
  {
    name: authV198ReNtLLMeta?.name ?? "auth",
    path: authV198ReNtLLMeta?.path ?? "/auth",
    meta: authV198ReNtLLMeta || {},
    alias: authV198ReNtLLMeta?.alias || [],
    redirect: authV198ReNtLLMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: basic_45informationn8q3WCHe7iMeta?.name ?? "basic-information",
    path: basic_45informationn8q3WCHe7iMeta?.path ?? "/basic-information",
    meta: basic_45informationn8q3WCHe7iMeta || {},
    alias: basic_45informationn8q3WCHe7iMeta?.alias || [],
    redirect: basic_45informationn8q3WCHe7iMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/basic-information.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93Lq54qzWGUiMeta?.name ?? "basic-information-id",
    path: _91id_93Lq54qzWGUiMeta?.path ?? ":id()",
    meta: _91id_93Lq54qzWGUiMeta || {},
    alias: _91id_93Lq54qzWGUiMeta?.alias || [],
    redirect: _91id_93Lq54qzWGUiMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/basic-information/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_937gkwADbSVOMeta?.name ?? undefined,
    path: _91id_937gkwADbSVOMeta?.path ?? "/clients/:id()",
    meta: _91id_937gkwADbSVOMeta || {},
    alias: _91id_937gkwADbSVOMeta?.alias || [],
    redirect: _91id_937gkwADbSVOMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id].vue").then(m => m.default || m),
    children: [
  {
    name: advisory_45letterJx9bvopQcwMeta?.name ?? "clients-id-advisory-letter",
    path: advisory_45letterJx9bvopQcwMeta?.path ?? "advisory-letter",
    meta: advisory_45letterJx9bvopQcwMeta || {},
    alias: advisory_45letterJx9bvopQcwMeta?.alias || [],
    redirect: advisory_45letterJx9bvopQcwMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/advisory-letter.vue").then(m => m.default || m)
  },
  {
    name: advisory_45letternkcBgRuD8RMeta?.name ?? "clients-id-docs-advisory-letter",
    path: advisory_45letternkcBgRuD8RMeta?.path ?? "docs/advisory-letter",
    meta: advisory_45letternkcBgRuD8RMeta || {},
    alias: advisory_45letternkcBgRuD8RMeta?.alias || [],
    redirect: advisory_45letternkcBgRuD8RMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/advisory-letter.vue").then(m => m.default || m)
  },
  {
    name: assets_45partnerIcvW3nEFfuMeta?.name ?? "clients-id-docs-assets-partner",
    path: assets_45partnerIcvW3nEFfuMeta?.path ?? "docs/assets-partner",
    meta: assets_45partnerIcvW3nEFfuMeta || {},
    alias: assets_45partnerIcvW3nEFfuMeta?.alias || [],
    redirect: assets_45partnerIcvW3nEFfuMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/assets-partner.vue").then(m => m.default || m)
  },
  {
    name: assetsRrCGqkVIMaMeta?.name ?? "clients-id-docs-assets",
    path: assetsRrCGqkVIMaMeta?.path ?? "docs/assets",
    meta: assetsRrCGqkVIMaMeta || {},
    alias: assetsRrCGqkVIMaMeta?.alias || [],
    redirect: assetsRrCGqkVIMaMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/assets.vue").then(m => m.default || m)
  },
  {
    name: commentst66sztRquQMeta?.name ?? "clients-id-docs-comments",
    path: commentst66sztRquQMeta?.path ?? "docs/comments",
    meta: commentst66sztRquQMeta || {},
    alias: commentst66sztRquQMeta?.alias || [],
    redirect: commentst66sztRquQMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/comments.vue").then(m => m.default || m)
  },
  {
    name: engagment_45letterd6S9lcRBzFMeta?.name ?? "clients-id-docs-engagment-letter",
    path: engagment_45letterd6S9lcRBzFMeta?.path ?? "docs/engagment-letter",
    meta: engagment_45letterd6S9lcRBzFMeta || {},
    alias: engagment_45letterd6S9lcRBzFMeta?.alias || [],
    redirect: engagment_45letterd6S9lcRBzFMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/engagment-letter.vue").then(m => m.default || m)
  },
  {
    name: familyWo331rTO7eMeta?.name ?? "clients-id-docs-family",
    path: familyWo331rTO7eMeta?.path ?? "docs/family",
    meta: familyWo331rTO7eMeta || {},
    alias: familyWo331rTO7eMeta?.alias || [],
    redirect: familyWo331rTO7eMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/family.vue").then(m => m.default || m)
  },
  {
    name: financial_45viewsPX9tcbNgM3Meta?.name ?? "clients-id-docs-financial-views",
    path: financial_45viewsPX9tcbNgM3Meta?.path ?? "docs/financial-views",
    meta: financial_45viewsPX9tcbNgM3Meta || {},
    alias: financial_45viewsPX9tcbNgM3Meta?.alias || [],
    redirect: financial_45viewsPX9tcbNgM3Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/financial-views.vue").then(m => m.default || m)
  },
  {
    name: indextn0i7wpAyrMeta?.name ?? "clients-id-docs",
    path: indextn0i7wpAyrMeta?.path ?? "docs",
    meta: indextn0i7wpAyrMeta || {},
    alias: indextn0i7wpAyrMeta?.alias || [],
    redirect: indextn0i7wpAyrMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/index.vue").then(m => m.default || m)
  },
  {
    name: marital_45property_45partnerf6KQMn5u9hMeta?.name ?? "clients-id-docs-marital-property-partner",
    path: marital_45property_45partnerf6KQMn5u9hMeta?.path ?? "docs/marital-property-partner",
    meta: marital_45property_45partnerf6KQMn5u9hMeta || {},
    alias: marital_45property_45partnerf6KQMn5u9hMeta?.alias || [],
    redirect: marital_45property_45partnerf6KQMn5u9hMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/marital-property-partner.vue").then(m => m.default || m)
  },
  {
    name: marital_45propertyz3MWFXcF22Meta?.name ?? "clients-id-docs-marital-property",
    path: marital_45propertyz3MWFXcF22Meta?.path ?? "docs/marital-property",
    meta: marital_45propertyz3MWFXcF22Meta || {},
    alias: marital_45propertyz3MWFXcF22Meta?.alias || [],
    redirect: marital_45propertyz3MWFXcF22Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/marital-property.vue").then(m => m.default || m)
  },
  {
    name: questionsKgQMvIhqmKMeta?.name ?? "clients-id-docs-questions",
    path: questionsKgQMvIhqmKMeta?.path ?? "docs/questions",
    meta: questionsKgQMvIhqmKMeta || {},
    alias: questionsKgQMvIhqmKMeta?.alias || [],
    redirect: questionsKgQMvIhqmKMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/docs/questions.vue").then(m => m.default || m)
  },
  {
    name: indexukfgsWVD5UMeta?.name ?? "clients-id",
    path: indexukfgsWVD5UMeta?.path ?? "",
    meta: indexukfgsWVD5UMeta || {},
    alias: indexukfgsWVD5UMeta?.alias || [],
    redirect: indexukfgsWVD5UMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: original_45draftr711Nc86o9Meta?.name ?? "clients-id-original-draft",
    path: original_45draftr711Nc86o9Meta?.path ?? "original-draft",
    meta: original_45draftr711Nc86o9Meta || {},
    alias: original_45draftr711Nc86o9Meta?.alias || [],
    redirect: original_45draftr711Nc86o9Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/clients/[id]/original-draft.vue").then(m => m.default || m)
  }
]
  },
  {
    name: create_45profilessD9iru46sMeta?.name ?? "create-profile",
    path: create_45profilessD9iru46sMeta?.path ?? "/create-profile",
    meta: create_45profilessD9iru46sMeta || {},
    alias: create_45profilessD9iru46sMeta?.alias || [],
    redirect: create_45profilessD9iru46sMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/create-profile.vue").then(m => m.default || m)
  },
  {
    name: editor_45testpZZNUeGRODMeta?.name ?? "editor-test",
    path: editor_45testpZZNUeGRODMeta?.path ?? "/editor-test",
    meta: editor_45testpZZNUeGRODMeta || {},
    alias: editor_45testpZZNUeGRODMeta?.alias || [],
    redirect: editor_45testpZZNUeGRODMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/editor-test.vue").then(m => m.default || m)
  },
  {
    name: eligibilityf1ZrsDCNcCMeta?.name ?? "eligibility",
    path: eligibilityf1ZrsDCNcCMeta?.path ?? "/eligibility",
    meta: eligibilityf1ZrsDCNcCMeta || {},
    alias: eligibilityf1ZrsDCNcCMeta?.alias || [],
    redirect: eligibilityf1ZrsDCNcCMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/eligibility.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_930VjZB0uhn4Meta?.name ?? "eligibility-id",
    path: _91id_930VjZB0uhn4Meta?.path ?? ":id()",
    meta: _91id_930VjZB0uhn4Meta || {},
    alias: _91id_930VjZB0uhn4Meta?.alias || [],
    redirect: _91id_930VjZB0uhn4Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/eligibility/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: financial_45philosophyvteLhKXBXxMeta?.name ?? "financial-philosophy",
    path: financial_45philosophyvteLhKXBXxMeta?.path ?? "/financial-philosophy",
    meta: financial_45philosophyvteLhKXBXxMeta || {},
    alias: financial_45philosophyvteLhKXBXxMeta?.alias || [],
    redirect: financial_45philosophyvteLhKXBXxMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/financial-philosophy.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93nW51M3hbq2Meta?.name ?? "financial-philosophy-id",
    path: _91id_93nW51M3hbq2Meta?.path ?? ":id()",
    meta: _91id_93nW51M3hbq2Meta || {},
    alias: _91id_93nW51M3hbq2Meta?.alias || [],
    redirect: _91id_93nW51M3hbq2Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/financial-philosophy/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: ineligibleWUA0xgMT9gMeta?.name ?? "ineligible",
    path: ineligibleWUA0xgMT9gMeta?.path ?? "/ineligible",
    meta: ineligibleWUA0xgMT9gMeta || {},
    alias: ineligibleWUA0xgMT9gMeta?.alias || [],
    redirect: ineligibleWUA0xgMT9gMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/ineligible.vue").then(m => m.default || m),
    children: [
  {
    name: date19f3dQ6m8rMeta?.name ?? "ineligible-date",
    path: date19f3dQ6m8rMeta?.path ?? "date",
    meta: date19f3dQ6m8rMeta || {},
    alias: date19f3dQ6m8rMeta?.alias || [],
    redirect: date19f3dQ6m8rMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/ineligible/date.vue").then(m => m.default || m)
  },
  {
    name: stateVndyr0vPkdMeta?.name ?? "ineligible-state",
    path: stateVndyr0vPkdMeta?.path ?? "state",
    meta: stateVndyr0vPkdMeta || {},
    alias: stateVndyr0vPkdMeta?.alias || [],
    redirect: stateVndyr0vPkdMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/ineligible/state.vue").then(m => m.default || m)
  }
]
  },
  {
    name: verifyETLyMudwYuMeta?.name ?? "invite-verify",
    path: verifyETLyMudwYuMeta?.path ?? "/invite/verify",
    meta: verifyETLyMudwYuMeta || {},
    alias: verifyETLyMudwYuMeta?.alias || [],
    redirect: verifyETLyMudwYuMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/invite/verify.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45authfYOiA6414sMeta?.name ?? "lawyer-auth",
    path: lawyer_45authfYOiA6414sMeta?.path ?? "/lawyer-auth",
    meta: lawyer_45authfYOiA6414sMeta || {},
    alias: lawyer_45authfYOiA6414sMeta?.alias || [],
    redirect: lawyer_45authfYOiA6414sMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-auth.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45dashboardrDzTVqukOyMeta?.name ?? "lawyer-dashboard",
    path: lawyer_45dashboardrDzTVqukOyMeta?.path ?? "/lawyer-dashboard",
    meta: lawyer_45dashboardrDzTVqukOyMeta || {},
    alias: lawyer_45dashboardrDzTVqukOyMeta?.alias || [],
    redirect: lawyer_45dashboardrDzTVqukOyMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-dashboard.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45login5EhNeRgHNrMeta?.name ?? "lawyer-login",
    path: lawyer_45login5EhNeRgHNrMeta?.path ?? "/lawyer-login",
    meta: lawyer_45login5EhNeRgHNrMeta || {},
    alias: lawyer_45login5EhNeRgHNrMeta?.alias || [],
    redirect: lawyer_45login5EhNeRgHNrMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-login.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45logoutc7J2120KF1Meta?.name ?? "lawyer-logout",
    path: lawyer_45logoutc7J2120KF1Meta?.path ?? "/lawyer-logout",
    meta: lawyer_45logoutc7J2120KF1Meta || {},
    alias: lawyer_45logoutc7J2120KF1Meta?.alias || [],
    redirect: lawyer_45logoutc7J2120KF1Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-logout.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45reviewVhPEL9dp08Meta?.name ?? undefined,
    path: lawyer_45reviewVhPEL9dp08Meta?.path ?? "/lawyer-review",
    meta: lawyer_45reviewVhPEL9dp08Meta || {},
    alias: lawyer_45reviewVhPEL9dp08Meta?.alias || [],
    redirect: lawyer_45reviewVhPEL9dp08Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review.vue").then(m => m.default || m),
    children: [
  {
    name: advisory_45letter_45reviewwg31btdtMEMeta?.name ?? "lawyer-review-advisory-letter-review",
    path: advisory_45letter_45reviewwg31btdtMEMeta?.path ?? "advisory-letter-review",
    meta: advisory_45letter_45reviewwg31btdtMEMeta || {},
    alias: advisory_45letter_45reviewwg31btdtMEMeta?.alias || [],
    redirect: advisory_45letter_45reviewwg31btdtMEMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/advisory-letter-review.vue").then(m => m.default || m)
  },
  {
    name: advisory_45letterMlzpAWz50iMeta?.name ?? "lawyer-review-advisory-letter",
    path: advisory_45letterMlzpAWz50iMeta?.path ?? "advisory-letter",
    meta: advisory_45letterMlzpAWz50iMeta || {},
    alias: advisory_45letterMlzpAWz50iMeta?.alias || [],
    redirect: advisory_45letterMlzpAWz50iMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/advisory-letter.vue").then(m => m.default || m)
  },
  {
    name: choose_45lawyerXJsIHwly3RMeta?.name ?? "lawyer-review-choose-lawyer",
    path: choose_45lawyerXJsIHwly3RMeta?.path ?? "choose-lawyer",
    meta: choose_45lawyerXJsIHwly3RMeta || {},
    alias: choose_45lawyerXJsIHwly3RMeta?.alias || [],
    redirect: choose_45lawyerXJsIHwly3RMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/choose-lawyer.vue").then(m => m.default || m)
  },
  {
    name: draftEZqsotbNjAMeta?.name ?? "lawyer-review-draft",
    path: draftEZqsotbNjAMeta?.path ?? "draft",
    meta: draftEZqsotbNjAMeta || {},
    alias: draftEZqsotbNjAMeta?.alias || [],
    redirect: draftEZqsotbNjAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/draft.vue").then(m => m.default || m)
  },
  {
    name: engagement_45letter_45reviewZlX9oSw3hRMeta?.name ?? "lawyer-review-engagement-letter-review",
    path: engagement_45letter_45reviewZlX9oSw3hRMeta?.path ?? "engagement-letter-review",
    meta: engagement_45letter_45reviewZlX9oSw3hRMeta || {},
    alias: engagement_45letter_45reviewZlX9oSw3hRMeta?.alias || [],
    redirect: engagement_45letter_45reviewZlX9oSw3hRMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/engagement-letter-review.vue").then(m => m.default || m)
  },
  {
    name: engagement_45letter3Ads5iK1ygMeta?.name ?? "lawyer-review-engagement-letter",
    path: engagement_45letter3Ads5iK1ygMeta?.path ?? "engagement-letter",
    meta: engagement_45letter3Ads5iK1ygMeta || {},
    alias: engagement_45letter3Ads5iK1ygMeta?.alias || [],
    redirect: engagement_45letter3Ads5iK1ygMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/engagement-letter.vue").then(m => m.default || m)
  },
  {
    name: finalizedVF0ipL2Zn4Meta?.name ?? "lawyer-review-finalized",
    path: finalizedVF0ipL2Zn4Meta?.path ?? "finalized",
    meta: finalizedVF0ipL2Zn4Meta || {},
    alias: finalizedVF0ipL2Zn4Meta?.alias || [],
    redirect: finalizedVF0ipL2Zn4Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/finalized.vue").then(m => m.default || m)
  },
  {
    name: indexXCMD03AveLMeta?.name ?? "lawyer-review",
    path: indexXCMD03AveLMeta?.path ?? "",
    meta: indexXCMD03AveLMeta || {},
    alias: indexXCMD03AveLMeta?.alias || [],
    redirect: indexXCMD03AveLMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/index.vue").then(m => m.default || m)
  },
  {
    name: own_45lawyer1qOB9en47HMeta?.name ?? "lawyer-review-own-lawyer",
    path: own_45lawyer1qOB9en47HMeta?.path ?? "own-lawyer",
    meta: own_45lawyer1qOB9en47HMeta || {},
    alias: own_45lawyer1qOB9en47HMeta?.alias || [],
    redirect: own_45lawyer1qOB9en47HMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/own-lawyer.vue").then(m => m.default || m)
  },
  {
    name: previewIf3GBcsZhBMeta?.name ?? "lawyer-review-preview",
    path: previewIf3GBcsZhBMeta?.path ?? "preview",
    meta: previewIf3GBcsZhBMeta || {},
    alias: previewIf3GBcsZhBMeta?.alias || [],
    redirect: previewIf3GBcsZhBMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/preview.vue").then(m => m.default || m)
  },
  {
    name: questionsTr0RikSLLqMeta?.name ?? "lawyer-review-questions",
    path: questionsTr0RikSLLqMeta?.path ?? "questions",
    meta: questionsTr0RikSLLqMeta || {},
    alias: questionsTr0RikSLLqMeta?.alias || [],
    redirect: questionsTr0RikSLLqMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/questions.vue").then(m => m.default || m)
  },
  {
    name: your_45reviewTvaPHH6FcAMeta?.name ?? "lawyer-review-your-review",
    path: your_45reviewTvaPHH6FcAMeta?.path ?? "your-review",
    meta: your_45reviewTvaPHH6FcAMeta || {},
    alias: your_45reviewTvaPHH6FcAMeta?.alias || [],
    redirect: your_45reviewTvaPHH6FcAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer-review/your-review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: verifylEA18QJ7UUMeta?.name ?? "lawyer-invite-verify",
    path: verifylEA18QJ7UUMeta?.path ?? "/lawyer/invite/verify",
    meta: verifylEA18QJ7UUMeta || {},
    alias: verifylEA18QJ7UUMeta?.alias || [],
    redirect: verifylEA18QJ7UUMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lawyer/invite/verify.vue").then(m => m.default || m)
  },
  {
    name: loginjhbtmcfyq0Meta?.name ?? "login",
    path: loginjhbtmcfyq0Meta?.path ?? "/login",
    meta: loginjhbtmcfyq0Meta || {},
    alias: loginjhbtmcfyq0Meta?.alias || [],
    redirect: loginjhbtmcfyq0Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutRNSjl0CyuuMeta?.name ?? "logout",
    path: logoutRNSjl0CyuuMeta?.path ?? "/logout",
    meta: logoutRNSjl0CyuuMeta || {},
    alias: logoutRNSjl0CyuuMeta?.alias || [],
    redirect: logoutRNSjl0CyuuMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: my_45assetshFoyAmV5AbMeta?.name ?? "my-assets",
    path: my_45assetshFoyAmV5AbMeta?.path ?? "/my-assets",
    meta: my_45assetshFoyAmV5AbMeta || {},
    alias: my_45assetshFoyAmV5AbMeta?.alias || [],
    redirect: my_45assetshFoyAmV5AbMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/my-assets.vue").then(m => m.default || m)
  },
  {
    name: my_45family98tpEpqnc1Meta?.name ?? "my-family",
    path: my_45family98tpEpqnc1Meta?.path ?? "/my-family",
    meta: my_45family98tpEpqnc1Meta || {},
    alias: my_45family98tpEpqnc1Meta?.alias || [],
    redirect: my_45family98tpEpqnc1Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/my-family.vue").then(m => m.default || m)
  },
  {
    name: advisory_45letterHiDAL41OYJMeta?.name ?? "our-prenup-advisory-letter",
    path: advisory_45letterHiDAL41OYJMeta?.path ?? "/our-prenup/advisory-letter",
    meta: advisory_45letterHiDAL41OYJMeta || {},
    alias: advisory_45letterHiDAL41OYJMeta?.alias || [],
    redirect: advisory_45letterHiDAL41OYJMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/advisory-letter.vue").then(m => m.default || m)
  },
  {
    name: engagement_45letter8ye2pxNDhqMeta?.name ?? "our-prenup-engagement-letter",
    path: engagement_45letter8ye2pxNDhqMeta?.path ?? "/our-prenup/engagement-letter",
    meta: engagement_45letter8ye2pxNDhqMeta || {},
    alias: engagement_45letter8ye2pxNDhqMeta?.alias || [],
    redirect: engagement_45letter8ye2pxNDhqMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/engagement-letter.vue").then(m => m.default || m)
  },
  {
    name: indexm1Tx3YQQMAMeta?.name ?? "our-prenup",
    path: indexm1Tx3YQQMAMeta?.path ?? "/our-prenup",
    meta: indexm1Tx3YQQMAMeta || {},
    alias: indexm1Tx3YQQMAMeta?.alias || [],
    redirect: indexm1Tx3YQQMAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/index.vue").then(m => m.default || m)
  },
  {
    name: lawyer_45commentsWsoXOKfqTWMeta?.name ?? "our-prenup-lawyer-comments",
    path: lawyer_45commentsWsoXOKfqTWMeta?.path ?? "/our-prenup/lawyer-comments",
    meta: lawyer_45commentsWsoXOKfqTWMeta || {},
    alias: lawyer_45commentsWsoXOKfqTWMeta?.alias || [],
    redirect: lawyer_45commentsWsoXOKfqTWMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/lawyer-comments.vue").then(m => m.default || m)
  },
  {
    name: my_45assetsn33QBZhoQUMeta?.name ?? "our-prenup-my-assets",
    path: my_45assetsn33QBZhoQUMeta?.path ?? "/our-prenup/my-assets",
    meta: my_45assetsn33QBZhoQUMeta || {},
    alias: my_45assetsn33QBZhoQUMeta?.alias || [],
    redirect: my_45assetsn33QBZhoQUMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/my-assets.vue").then(m => m.default || m)
  },
  {
    name: my_45familyvvcJoHquBsMeta?.name ?? "our-prenup-my-family",
    path: my_45familyvvcJoHquBsMeta?.path ?? "/our-prenup/my-family",
    meta: my_45familyvvcJoHquBsMeta || {},
    alias: my_45familyvvcJoHquBsMeta?.alias || [],
    redirect: my_45familyvvcJoHquBsMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/my-family.vue").then(m => m.default || m)
  },
  {
    name: my_45marital_45propertyLgdgjthoeiMeta?.name ?? "our-prenup-my-marital-property",
    path: my_45marital_45propertyLgdgjthoeiMeta?.path ?? "/our-prenup/my-marital-property",
    meta: my_45marital_45propertyLgdgjthoeiMeta || {},
    alias: my_45marital_45propertyLgdgjthoeiMeta?.alias || [],
    redirect: my_45marital_45propertyLgdgjthoeiMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/my-marital-property.vue").then(m => m.default || m)
  },
  {
    name: our_45financial_45viewscd86cpruCfMeta?.name ?? "our-prenup-our-financial-views",
    path: our_45financial_45viewscd86cpruCfMeta?.path ?? "/our-prenup/our-financial-views",
    meta: our_45financial_45viewscd86cpruCfMeta || {},
    alias: our_45financial_45viewscd86cpruCfMeta?.alias || [],
    redirect: our_45financial_45viewscd86cpruCfMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/our-financial-views.vue").then(m => m.default || m)
  },
  {
    name: partners_45assetsGGAFFASmedMeta?.name ?? "our-prenup-partners-assets",
    path: partners_45assetsGGAFFASmedMeta?.path ?? "/our-prenup/partners-assets",
    meta: partners_45assetsGGAFFASmedMeta || {},
    alias: partners_45assetsGGAFFASmedMeta?.alias || [],
    redirect: partners_45assetsGGAFFASmedMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/partners-assets.vue").then(m => m.default || m)
  },
  {
    name: partners_45marital_45propertynt5T9VPPV6Meta?.name ?? "our-prenup-partners-marital-property",
    path: partners_45marital_45propertynt5T9VPPV6Meta?.path ?? "/our-prenup/partners-marital-property",
    meta: partners_45marital_45propertynt5T9VPPV6Meta || {},
    alias: partners_45marital_45propertynt5T9VPPV6Meta?.alias || [],
    redirect: partners_45marital_45propertynt5T9VPPV6Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/partners-marital-property.vue").then(m => m.default || m)
  },
  {
    name: questionsRi5qVFed8jMeta?.name ?? "our-prenup-questions",
    path: questionsRi5qVFed8jMeta?.path ?? "/our-prenup/questions",
    meta: questionsRi5qVFed8jMeta || {},
    alias: questionsRi5qVFed8jMeta?.alias || [],
    redirect: questionsRi5qVFed8jMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/questions.vue").then(m => m.default || m)
  },
  {
    name: sign_45prenupNGrr9J7xLeMeta?.name ?? "our-prenup-sign-prenup",
    path: sign_45prenupNGrr9J7xLeMeta?.path ?? "/our-prenup/sign-prenup",
    meta: sign_45prenupNGrr9J7xLeMeta || {},
    alias: sign_45prenupNGrr9J7xLeMeta?.alias || [],
    redirect: sign_45prenupNGrr9J7xLeMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/sign-prenup.vue").then(m => m.default || m)
  },
  {
    name: waiver_45of_45attorneyOyw9Qu27fvMeta?.name ?? "our-prenup-waiver-of-attorney",
    path: waiver_45of_45attorneyOyw9Qu27fvMeta?.path ?? "/our-prenup/waiver-of-attorney",
    meta: waiver_45of_45attorneyOyw9Qu27fvMeta || {},
    alias: waiver_45of_45attorneyOyw9Qu27fvMeta?.alias || [],
    redirect: waiver_45of_45attorneyOyw9Qu27fvMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/our-prenup/waiver-of-attorney.vue").then(m => m.default || m)
  },
  {
    name: packageQyReJjidrGMeta?.name ?? "package",
    path: packageQyReJjidrGMeta?.path ?? "/package",
    meta: packageQyReJjidrGMeta || {},
    alias: packageQyReJjidrGMeta?.alias || [],
    redirect: packageQyReJjidrGMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/package.vue").then(m => m.default || m)
  },
  {
    name: postnup_45waitlistnfTsC5TTfOMeta?.name ?? "postnup-waitlist",
    path: postnup_45waitlistnfTsC5TTfOMeta?.path ?? "/postnup-waitlist",
    meta: postnup_45waitlistnfTsC5TTfOMeta || {},
    alias: postnup_45waitlistnfTsC5TTfOMeta?.alias || [],
    redirect: postnup_45waitlistnfTsC5TTfOMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/postnup-waitlist.vue").then(m => m.default || m)
  },
  {
    name: scroll_45progress_45demoOcuunN6MnxMeta?.name ?? "scroll-progress-demo",
    path: scroll_45progress_45demoOcuunN6MnxMeta?.path ?? "/scroll-progress-demo",
    meta: scroll_45progress_45demoOcuunN6MnxMeta || {},
    alias: scroll_45progress_45demoOcuunN6MnxMeta?.alias || [],
    redirect: scroll_45progress_45demoOcuunN6MnxMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/scroll-progress-demo.vue").then(m => m.default || m)
  },
  {
    name: signupqU7ffU0d9PMeta?.name ?? "signup",
    path: signupqU7ffU0d9PMeta?.path ?? "/signup",
    meta: signupqU7ffU0d9PMeta || {},
    alias: signupqU7ffU0d9PMeta?.alias || [],
    redirect: signupqU7ffU0d9PMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: user_45advisement_45letterjHiCdasUsPMeta?.name ?? "user-advisement-letter",
    path: user_45advisement_45letterjHiCdasUsPMeta?.path ?? "/user-advisement-letter",
    meta: user_45advisement_45letterjHiCdasUsPMeta || {},
    alias: user_45advisement_45letterjHiCdasUsPMeta?.alias || [],
    redirect: user_45advisement_45letterjHiCdasUsPMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/user-advisement-letter.vue").then(m => m.default || m)
  },
  {
    name: waiver_45of_45attorneyt7734rqmc3Meta?.name ?? "waiver-of-attorney",
    path: waiver_45of_45attorneyt7734rqmc3Meta?.path ?? "/waiver-of-attorney",
    meta: waiver_45of_45attorneyt7734rqmc3Meta || {},
    alias: waiver_45of_45attorneyt7734rqmc3Meta?.alias || [],
    redirect: waiver_45of_45attorneyt7734rqmc3Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/waiver-of-attorney.vue").then(m => m.default || m)
  }
]